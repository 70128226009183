<template>
    <div>
        <b-modal ref="map-filters-ref" id="modal-map-filters"  scrollable :title="$t('map-filters')" :hide-footer="true">

            <div class="row">
                <div class="col-lg-6 col-md-12 stretch-card new-stretch-card">
                    <b-form-group id="input-group-1" :label="$t('date-from')" label-for="input-1">
                        <b-form-input id="input-1" v-model="dateFrom" type="date" :placeholder="$t('date-from')" required></b-form-input>
                    </b-form-group>
    
                    <b-form-group id="input-group-1" :label="$t('date-to')" label-for="input-1" class="ml-1">
                        <b-form-input id="input-1" v-model="dateTo" type="date" :placeholder="$t('date-to')" required></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-group id="spot_id" :label="$t('enter-spot-ids')" label-for="spot_id">
                        <b-form-input id="spot_id" name="spot_id" type="text" v-model="spotId" :placeholder="$t('Comma Separated spot ids')"></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="accordion" role="tablist">
                <b-card no-body class="mb-1" v-if="surveys.length !== 0 && !isLoading && (this.version_id == 2 || this.version_id == 3)">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="info">{{ $t('Filter By Survey') }}</b-button>
                    </b-card-header>
                    <b-collapse v-if="surveys.length > 0 && !isLoading" id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{$t('Select survey name to filer spots')}}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllSurvey" v-model="selectAllSurvey" checked-value=true unchecked-value=false @change="allSurveyName">
                                        {{ $t('select-all') }}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(survey, index) in surveys" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-survey-name" :value="survey.id" v-model="checkedSurvey" checked-value=true unchecked-value=false @change="changeCheckbox($event,'surveyName')">
                                            {{ survey.name }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{$t('Select survey date to filer spots')}}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllSurveyDate" v-model="selectAllSurveyDate" checked-value=true unchecked-value=false @change="allSurveyDate">
                                        {{ $t('select-all') }}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(survey, index) in surveys" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-survey-date" :value="survey.id" v-model="checkedSurveyDate" checked-value=true unchecked-value=false @change="changeCheckbox($event,'surveyDate')">
                                            {{ survey.survey_date }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="categories.length !== 0 && !isLoading">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="info">{{ $t('filter-by-category') }}</b-button>
                    </b-card-header>
                    <b-collapse v-if="categories.length > 0 && !isLoading" id="accordion-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{$t('select-categories-to-filter-spots')}}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllCategory" v-model="selectAllCategory" checked-value=true unchecked-value=false @change="allCategory">
                                        {{ $t('select-all') }}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(category, index) in categories" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-category" :value="category.id" v-model="checkedCategory" checked-value=true unchecked-value=false @change="changeCheckbox($event,'category')">
                                            {{ category.name }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
    
                <b-card no-body class="mb-1" v-if="locations.length !== 0 && !isLoading">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-3 variant="info">{{ $t('filter-by-locations') }}</b-button>
                    </b-card-header>
                    <b-collapse v-if="locations.length > 0 && !isLoading" id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{ $t('select-locations-to-filter-spots') }}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllLocations" v-model="selectAllLocations" checked-value=true unchecked-value=false @change="allLocations">
                                      {{$t('select-all')}}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(location, index) in locations" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-location" v-model="checkedLocations" :value="location.id" checked-value=true unchecked-value=false @change="changeCheckbox($event,'location')">
                                            {{ location.name }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
    
                <b-card no-body class="mb-1" v-if="spotTypes.length !== 0 && !isLoading">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-4 variant="info">{{ $t('filter-by-spot-types') }}</b-button>
                    </b-card-header>
                    <b-collapse v-if="spotTypes.length > 0 && !isLoading" id="accordion-4" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{ $t('select-spot-type-to-filter-spots') }}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllSpotTypes" v-model="selectAllSpotTypes" checked-value=true unchecked-value=false @change="allSpotTypes">
                                       {{$t('select-all')}}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(spotType, index) in spotTypes" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-spot-type" v-model="checkedSpotTypes" :value="spotType.id" checked-value=true unchecked-value=false @change="changeCheckbox($event,'spot_type')">
                                            {{ spotType.name }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
    
                <b-card no-body class="mb-1" v-if="questions.length !== 0 && !isLoading">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-5 variant="info">{{$t('filter-by-questions')}}</b-button>
                    </b-card-header>
                    <b-collapse v-if="questions.length > 0 && !isLoading" id="accordion-5" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{ $t('select-the-questions-to-filter-spots') }}:</h6>
                                </div>
                                <div>
                                    <b-form-checkbox name="selectAllQuestion" v-model="selectAllQuestion" checked-value=true unchecked-value=false @change="allQuestion">
                                       {{$t('select-all')}}
                                    </b-form-checkbox>
                                </div>
                                <div v-for="(question, index) in questions" :key="index">
                                    <div class="row ml-3" style="display: block;">
                                        <b-form-checkbox name="checkbox-question" :value="question.id" v-model="checkedQuestions" checked-value=true unchecked-value=false @change="changeCheckbox($event,'question')">
                                            {{ question.question }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1" v-if="questions.length !== 0 && !isLoading">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-6 variant="info">{{ $t('filter-by-answer') }}</b-button>
                    </b-card-header>
                    <b-collapse v-if="selectedQuestions.length > 0 && !isLoading" id="accordion-6" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div class="row mb-4 mt-4">
                                <b-form-checkbox name="checkbox-count-range" v-model="countRangeFilter" checked-value=true unchecked-value=false>
                                    {{  $t('include-count-range-filter') }}
                                </b-form-checkbox>
                                <div class="col-md-12">
                                    <!-- <b-form-group id="spot_id" :label="$t('Answer Count Range')" label-for="spot_id"> -->
                                        <vue-slider v-model="countRange" :order="true" :max="maxBarRange" :marks="false" :data="marksData" :enable-cross="false"></vue-slider>
                                    <!-- </b-form-group> -->
                                    <!-- {{ countRange }} -->
                                    <span>{{$t('range-from')}} {{ countRange[0] }}</span> <span>{{$t('range-to')}} {{ countRange[1] }}</span>
                                </div>
                            </div>
                            <hr>
                            <div class="row m-3" style="display: block;">
                                <div>
                                    <h6>{{ $t('select-the-questions-to-filter-spots-by-answer') }}:</h6>
                                </div>
                                <div class="row ml-3" style="display: block;">
                                    <div v-for="(question, index) in selectedQuestions" :key="index">
                                        <div class="row ml-3" style="display: block;">
                                            <b-form-checkbox name="checkbox-aquestion" v-model="question.checked" checked-value=true unchecked-value=false @change="changeCheckboxValue($event,index,'main')">
                                                {{ question.question }}
                                            </b-form-checkbox>
                                        </div>
                                        <div class="row ml-3" v-if="question.checked === true && question.responses && question.responses.length > 0 && question.subQuestions.length !=0" style="display: block;">
                                            <b-form-select @change="changeQuestionDropdown(question)" v-model="question.response"
                                               class="custom-select ">
                                               <option v-for="(option,index) in question.responses" :key="index" :options="option.question_response" :value="option.question_response">{{ option.question_response }}</option>
                                            </b-form-select>
                                        </div >
                                        <div class="row ml-3" v-else-if="question.checked === true && question.responses.length === 0" style="display: block;">
                                            <b-form-input name="checkbox-answer" :placeholder="$t('Type Possible Answers....')" v-model="question.response"></b-form-input>
                                        </div>
                                        <div class="row ml-3" v-else-if="question.checked && question.responses.length !== 0 && question.subQuestions.length === 0" style="display: block;">
                                            <h6>{{ $t('select-question-answers') }}:</h6>
                                            <div v-for="(question_responses, index) in question.responses" :key="index">
                                                <b-form-checkbox name="checkbox-sub_question" v-model="question_responses.checked" checked-value=true unchecked-value=false>
                                                    {{ question_responses.question_response }}
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="row ml-4 mt-1" v-if="question.checked === true && question.response === 'Yes' && question.subQuestions.length > 0" style="display: block;">
                                            <div>
                                                <h6>{{ $t('select-sub-questions') }}:</h6>
                                            </div>
                                            <div v-for="(sub_question, sub_index) in question.subQuestions" :key="sub_index">
                                                <b-form-checkbox name="checkbox-sub_question" v-model="sub_question.checked" checked-value=true unchecked-value=false>
                                                    {{ sub_question.sub_question }}
                                                </b-form-checkbox>
                                                <div class="row ml-3" v-if="sub_question.checked === true && sub_question.answer_type === 'I'" style="display: block;">
                                                    <div>
                                                        <h6>{{ $t('add-sub-questions-answers') }}:</h6>
                                                    </div>
                                                    <b-form-input name="checkbox-answer" :placeholder="$t('Type Possible Answers....')" v-model="sub_question.response"></b-form-input>
                                                </div>
                                                <div class="row ml-3" v-if="sub_question.checked === true && sub_question.answer_type === 'D' && sub_question.options.length > 0" style="display: block;">
                                                    <div>
                                                        <h6>{{$t('select-sub-questions-answers')}}:</h6>
                                                    </div>
                                                    <div v-for="(sub_question_option, index) in sub_question.options" :key="index">
                                                        <b-form-checkbox name="checkbox-sub_question" v-model="sub_question_option.checked" checked-value=true unchecked-value=false>
                                                            {{ sub_question_option.response_name }}
                                                        </b-form-checkbox>
                                                    </div>
                                                    <!-- <div>
                                                        <b-form-select v-model="sub_question.response">
                                                            <option v-for="(option,index) in sub_question.options" :key="index" :value="option.response_name">{{ option.response_name }}</option>
                                                        </b-form-select>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
    
            <div class="row no-question">
                <Circle5 v-if="isLoading"></Circle5>
            </div>
    
            <div class="row no-question" v-if="questions.length === 0 && categories.length === 0 && locations.length === 0 && spotTypes.length === 0 && !isLoading">
                <h7>{{  $t('no-category-spot-type-location-filterable-questions-found') }}</h7>
            </div>
            <!-- <div class="row m-3" v-if="questions.length > 0 && !isLoading" style="display: block;">
                    <div>
                        <h6>Select the questions to filter spots:</h6>
                    </div>
                    <div>
                        <b-form-checkbox name="selectAll" v-model="selectAll" checked-value=true unchecked-value=false @change="selectAllEvent">Select All</b-form-checkbox>
                    </div>
                    <div v-for="(question, index) in questions" :key="index">
                        <div class="row ml-3" style="display: block;">
                            <b-form-checkbox name="checkbox-question" v-model="question.checked" :value="question.id" checked-value=true unchecked-value=false @change="changeCheckbox">
                                {{ question.question }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div> -->
            <hr>
            <div class="modal-btns">
                <div class="row m-1 float-right">
                    <b-button type="button" variant="success" class="btn btn-success btn-sm d-flex" @click="applyFilter">
                        <template v-if="isFiltering">
                            <i class="fas fa-spinner fa-spin"></i> {{ $t('filtering')}}...
                        </template>
                        <template v-else>
                            {{ $t('filter-btn') }}
                        </template>
                        
                    </b-button>
                </div>
                <div class="row m-1 float-right">
                    <b-button type="button" variant="secondary" class="btn-sm d-flex" @click="resetMap">
                        <template v-if="isResetting">
                            <i class="fas fa-spinner fa-spin"></i> {{ $t('reseting') }}...
                        </template>
                        <template v-else>
                            {{ $t('reset-btn') }}
                        </template>
                    </b-button>
                </div>
                <div class="row m-1 float-right">
                    <b-button type="button" variant="primary" class="btn btn-primary btn-sm d-flex" @click="closePopup">{{ $t('close-btn') }}
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import Vue from "vue";
    import API from "@/citizen_api";
    // import RangeSlider from "vue-simple-range-slider";
    import {Circle5} from 'vue-loading-spinner';
    import VueSlideBar from 'vue-slide-bar';
    import MultiRangeSlider from "multi-range-slider-vue";
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'
    export default {
        name: "modal-map-filters",
        props: ['client_url', 'user_type'],
        data() {
            return {
                questions: [],
                categories: [],
                surveys:[],
                locations: [],
                spotTypes: [],
                client_id: JSON.parse(localStorage.getItem("client_id")),
                isLoading: false,
                selectAllQuestion: false,
                selectAllAQuestion: false,
                selectAllQuestionAnswers: false,
                selectAllCategory: false,
                selectAllSurvey: false,
                selectAllSurveyDate: false,
                selectAllLocations: false,
                selectAllSpotTypes: false,
                selectedQuestions: [],
                checkedQuestions: [],
                checkedCategory: [],
                checkedSurvey: [],
                checkedSurveyDate: [],
                checkedLocations: [],
                checkedSpotTypes: [],
                selected: null,
                barMinValue: null,
                rangeMargin: 1,
                barMaxValue: null,
                minBarRange: null,
                maxBarRange: null,
                marksData: [],
                selected_questions: [],
                questions_answers: [],
                selected_questions_answer: '',
                question_responses: [],
                spotId: '',
                dateFrom:  '',
                DateTo: '',
                dateTo: '',
                countRange: [0, 100],
                defaultSliderValue: 1,
                countRangeFilter: false,
                isFiltering: false,
                isResetting: false,
                version_id: null,

            };
        },
        components: {
            Circle5,
            VueSlider
        },
        beforeMount() {},
        mounted() {
            this.getFiltersData();
        },
        methods: {
            changeQuestionDropdown(question) {
            console.log('question :', question.id);
                if(question.response === "No"){
                    const index = this.selectedQuestions.findIndex(q => {
                        return q.id === question.id;
                    });
                    this.selectedQuestions[index].subQuestions.forEach(sq => {
                        sq.checked = false;
                        if (sq.answer_type === "D") {
                                sq.options.forEach(opt => {
                                opt.checked = false;
                            });
                        } else if (sq.answer_type === "I") {
                            sq.response = "";
                        }
                    });
                }
            },
            changeSliderValue(data) {
                this.minBarRange =  Number(data.min_num);
                this.maxBarRange =  Number(data.max_num);
                this.countRange = [this.minBarRange, this.maxBarRange]
                this.marksData = data.range_array;
            },
            refreshSlide(){
                // this.$refs.slider.refresh();
            },
            getFiltersData() {
                this.isLoading = true;
                this.selected_questions = [];
                this.selected_questions_answer = '';
                let data = {
                    client_id: this.client_id,
                    client_url: this.client_url,
                    user_type: this.user_type,
                    question_responses: this.question_responses
                };
                API.getFiltersData(
                    data,
                    data => {
                        console.log('getFiltersData dasddsasdsaaaaa :', data);
                        this.selectedQuestions = data.data.selectedQuestions;
                        this.questions = data.data.questions;
                        this.categories = data.data.categories;
                        this.surveys = data.data.surveys;
                        console.log('this.surveys :', this.surveys);
                        this.locations = data.data.locations;
                        this.spotTypes = data.data.spot_types;
                        this.question_responses = data.data.question_responses;
                        this.minBarRange =  Number(data.range.min_num);
                        this.maxBarRange =  Number(data.range.max_num);
                        this.countRange = [this.minBarRange, this.maxBarRange];
                        this.marksData = data.range.range_array;
                        this.isLoading = false;
                        this.selectAllQuestion = false;
                        this.version_id = data.version_id;
                    },
                    err => {}
                );
            },
            getQuestionsAnswers() {
                // this.isLoading = true;
                let data = {
                    client_id: this.client_id,
                    selected_questions: this.selected_questions
                };
                API.getQuestionsAnswers(
                    data,
                    data => {
                        this.selected_questions_answer = [];
                        this.questions_answers = data.data.questions_answers;
                    },
                    err => {}
                );
            },
            applyFilter(param) {
                if(param === 'reset') {
                    this.isResetting = true;
                } else {
                    this.isFiltering = true;
                }
                if((this.dateFrom && !this.dateTo) || (!this.dateFrom && this.dateTo)) {
                    this.$snotify.error('Both Date Ranges should be selected');
                    return;
                }
                let data = {
                    spotId: this.spotId,
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    questions: [],
                    categories: [],
                    locations: [],
                    surveys: [],
                    spotTypes: [],
                    selected_questions: [],
                    selectedQuestions: this.selectedQuestions,
                    selected_questions_answer: this.selected_questions_answer,
                    question_responses: [],
                    countRangeFilter: this.countRangeFilter,
                    countRangeValues: this.countRange
                };
                var questionCheckboxes = document.querySelectorAll('input[name=checkbox-question]:checked')
                var categoryCheckboxes = document.querySelectorAll('input[name=checkbox-category]:checked')
                var locationsCheckboxes = document.querySelectorAll('input[name=checkbox-location]:checked')
                var surveyNameCheckboxes = document.querySelectorAll('input[name=checkbox-survey-name]:checked')
                var surveyDateCheckboxes = document.querySelectorAll('input[name=checkbox-survey-date]:checked')
                var spotTypeCheckboxes = document.querySelectorAll('input[name=checkbox-spot-type]:checked')
                var checkboxAquestion = document.querySelectorAll('input[name=checkbox-aquestion]:checked')
                // var checkboxAquestionAswer = document.querySelectorAll('input[name=checkbox-aquestion-answer]')
    
                // console.log(checkboxAquestionAswer.value);
                if (checkboxAquestion) {
                    for (let i = 0; i < checkboxAquestion.length; i++) {
                        if (checkboxAquestion[i].name !== 'selectAllAQuestion') {
                            data.selected_questions.push(checkboxAquestion[i].value)
                        }
                    }
                }
                // if (checkboxAquestionAswer) {
                //     for (let i = 0; i < checkboxAquestionAswer.length; i++) {
                //         if (checkboxAquestionAswer[i].name !== 'selectAllQuestionAnswer') {
                //             data.selected_questions_answer.push(checkboxAquestionAswer[i].value)
                //         }
                //     }
                // }
                if (questionCheckboxes) {
                    for (let i = 0; i < questionCheckboxes.length; i++) {
                        if (questionCheckboxes[i].name !== 'selectAllQuestion') {
                            data.questions.push(questionCheckboxes[i].value)
                        }
                    }
                }
                if (categoryCheckboxes) {
                    for (let i = 0; i < categoryCheckboxes.length; i++) {
                        if (categoryCheckboxes[i].name !== 'selectAllCategory') {
                            data.categories.push(categoryCheckboxes[i].value)
                        }
                    }
                }
                if (locationsCheckboxes) {
                    for (let i = 0; i < locationsCheckboxes.length; i++) {
                        if (locationsCheckboxes[i].name !== 'selectAllLocations') {
                            data.locations.push(locationsCheckboxes[i].value)
                        }
                    }
                }
                if (surveyNameCheckboxes) {
                    for (let i = 0; i < surveyNameCheckboxes.length; i++) {
                        if (surveyNameCheckboxes[i].name !== 'selectAllSurvey') {
                            data.surveys.push(surveyNameCheckboxes[i].value)
                        }
                    }
                }
                if (surveyDateCheckboxes) {
                    for (let i = 0; i < surveyDateCheckboxes.length; i++) {
                        if (surveyDateCheckboxes[i].name !== 'selectAllSurveyDate') {
                            data.surveys.push(surveyDateCheckboxes[i].value)
                        }
                    }
                }
                if (spotTypeCheckboxes) {
                    for (let i = 0; i < spotTypeCheckboxes.length; i++) {
                        if (spotTypeCheckboxes[i].name !== 'selectAllSpotTypes') {
                            data.spotTypes.push(spotTypeCheckboxes[i].value)
                        }
                    }
                }
                console.log('before filter', data)
                this.$emit('applyFilter', data);
            },
            // selectAllEvent(event) {
            //     console.log('called');
            //     var checkboxes = document.querySelectorAll('input[type="checkbox"]');
            //     console.log(checkboxes);
            //     for (var i = 0; i < checkboxes.length; i++) {
            //         if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAll') {
            //             if (event === true) {
            //                 checkboxes[i].checked = true;
            //             } else {
            //                 checkboxes[i].checked = false;
            //             }
            //         }
            //     }
            // },
            allQuestion(event) {
                this.checkedQuestions = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-question]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllQuestion') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedQuestions.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            allAQuestion(event) {
                this.selected_questions = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-aquestion]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllAQuestion') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.selected_questions.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
                // this.getQuestionsAnswers();
                console.log(this.selected_questions);
            },
            allQuestionAnswers(event) {
                this.selected_questions_answer = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-aquestion-answer]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllQuestionAnswers') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.selected_questions_answer.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
                console.log(this.selected_questions_answer);
            },
            allSurveyName(event) {
                this.checkedSurvey = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-survey-name]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllSurvey') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedSurvey.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            allSurveyDate(event) {
                this.checkedSurveyDate = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-survey-date]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllSurveyDate') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedSurveyDate.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            allCategory(event) {
                this.checkedCategory = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-category]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllCategory') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedCategory.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            allLocations(event) {
                this.checkedLocations = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-location]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllLocations') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedLocations.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            allSpotTypes(event) {
                this.checkedSpotTypes = [];
                var checkboxes = document.querySelectorAll('input[name=checkbox-spot-type]');
                console.log(checkboxes);
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type === 'checkbox' && checkboxes[i].name !== 'selectAllSpotTypes') {
                        if (event === true) {
                            checkboxes[i].checked = true;
                            this.checkedSpotTypes.push(checkboxes[i]._value);
                        } else {
                            checkboxes[i].checked = false;
                        }
                    }
                }
            },
            changeCheckboxValue(event, filter, type) {
                if (type === 'main') {
                    console.log(event, filter, type)
                    if (event === true) {
                        console.log(event, filter, type)
                        this.selectedQuestions[filter]['checked'] = true;
                        console.log(this.selectedQuestions[filter], event, filter, type, this.selectedQuestions[filter]['checked'])
                    } else {
                        this.selectedQuestions[filter]['checked'] = false;
                    }
                }
            },
            changeCheckbox(event, filter) {
                if (filter === 'question') {
                    if (event !== true) {
                        console.log(event, filter);
                        this.selectAllQuestion = false;
                    }
                }
                else if (filter === 'surveyName') {
                    if (event !== true) {
                        this.selectAllSurvey = false;
                    }
                }  
                else if (filter === 'surveyDate') {
                    if (event !== true) {
                        this.selectAllSurveyDate = false;
                    }
                } 
                 else if (filter === 'category') {
                    if (event !== true) {
                        this.selectAllCategory = false;
                    }
                } else if (filter === 'location') {
                    if (event !== true) {
                        this.selectAllLocations = false;
                    }
                } else if (filter === 'spot_type') {
                    if (event !== true) {
                        this.selectAllSpotTypes = false;
                    }
                } else if (filter === 'a_question') {
                    if (event !== true) {
                        console.log(this.selected_questions);
                        // this.getQuestionsAnswers();
                        this.selectAllAQuestion = false;
                    }
                } else if (filter === 'a_question_answer') {
                    if (event !== true) {
                        console.log(this.selected_questions_answer);
                        this.selectAllQuestionAnswers = false;
                    }
                }
            },
            selectAllLocationsEvent(event) {
                if (event !== true) {
                    this.selectAllCategory = false;
                }
            },
            closePopup() {
                this.$refs['map-filters-ref'].hide();
            },
            UpdateValues(e) {
                this.barMinValue = e.minValue;
                this.barMaxValue = e.maxValue;
            },
            // changeCategoryCheckbox(event){
            //     if(event !== true){
            //         this.selectAll = false;
            //     }
            // },
            resetMap() {
                // this.$EventBus.$emit('reinitializeMap');
                // this.$refs['map-filters-ref'].hide();
                this.isResetting = true;
                this.selected_questions = '';
                this.selectedQuestions.forEach((q, index) => {
                    q.checked = false;
                    q.response = '';
                    // to unchecked the question responses
                    q.responses.forEach((res) => {
                        res.checked = false;
                    })
                    q.subQuestions.forEach((subQuestion, index) => {
                        subQuestion.checked = false;
                        subQuestion.response = '';
                        subQuestion.options.forEach((option, index) => {
                            option.checked = false;
                        })
                    })
                })
                this.selected_questions_answer = '';
                this.$snotify.info('Map reinitializing...');
                this.selectAllCategory = false;
                this.selectAllSurvey = false;
                this.selectAllSurveyDate = false;
                this.selectAllAQuestion = false;
                this.selectAllQuestionAnswers = false;
                this.checkedCategory = [];
                this.checkedSurvey = [];
                this.checkedSurveyDate = [];
                this.selectAllLocations = false;
                this.checkedLocations = [];
                this.selectAllSpotTypes = false;
                this.checkedSpotTypes = [];
                this.selectAllQuestion = false;
                this.checkedQuestions = [];
                this.spotId = '';
                this.dateFrom = '';
                this.dateTo = '';
                this.countRangeFilter = false;
                // this.countRange[1] = this.maxBarRange;
                this.resetAllFilters();
                this.applyFilter('reset');
            },
            StopFilterLoading() {
            console.log('StopFilterLoading :');
                this.isFiltering = false;
            },
            StopResetLoading() {
            console.log('StopResetLoading :');
                this.isResetting = false;
            },
            resetAllFilters() {
                var checkboxAquestion = document.querySelectorAll('input[name=checkbox-aquestion]:checked')
                var questionCheckboxes = document.querySelectorAll('input[name=checkbox-question]:checked')
                var categoryCheckboxes = document.querySelectorAll('input[name=checkbox-category]:checked')
                var locationsCheckboxes = document.querySelectorAll('input[name=checkbox-location]:checked')
                var surveyNameCheckboxes = document.querySelectorAll('input[name=checkbox-survey-name]:checked')
                var surveyDateCheckboxes = document.querySelectorAll('input[name=checkbox-survey-date]:checked')
                var spotTypeCheckboxes = document.querySelectorAll('input[name=checkbox-spot-type]:checked')
    
                if (checkboxAquestion) {
                    for (let i = 0; i < checkboxAquestion.length; i++) {
                        if (checkboxAquestion[i].type === 'checkbox') {
                            checkboxAquestion[i].checked = false;
                        }
                    }
                }
                if (questionCheckboxes) {
                    for (let i = 0; i < questionCheckboxes.length; i++) {
                        if (questionCheckboxes[i].type === 'checkbox') {
                            questionCheckboxes[i].checked = false;
                        }
                    }
                }
                if (categoryCheckboxes) {
                    for (let i = 0; i < categoryCheckboxes.length; i++) {
                        if (categoryCheckboxes[i].type === 'checkbox') {
                            categoryCheckboxes[i].checked = false;
                        }
                    }
                }
                if (locationsCheckboxes) {
                    for (let i = 0; i < locationsCheckboxes.length; i++) {
                        if (locationsCheckboxes[i].type === 'checkbox') {
                            locationsCheckboxes[i].checked = false;
                        }
                    }
                }
                if (surveyNameCheckboxes) {
                    for (let i = 0; i < surveyNameCheckboxes.length; i++) {
                        if (surveyNameCheckboxes[i].type === 'checkbox') {
                            surveyNameCheckboxes[i].checked = false;
                        }
                    }
                }
                if (surveyDateCheckboxes) {
                    for (let i = 0; i < surveyDateCheckboxes.length; i++) {
                        if (surveyDateCheckboxes[i].type === 'checkbox') {
                            surveyDateCheckboxes[i].checked = false;
                        }
                    }
                }
                if (spotTypeCheckboxes) {
                    for (let i = 0; i < spotTypeCheckboxes.length; i++) {
                        if (spotTypeCheckboxes[i].type === 'checkbox') {
                            spotTypeCheckboxes[i].checked = false;
                        }
                    }
                }
            }
        },
        created() {
        },
        destroyed() {},
        watch: {}
    };
</script>

<style lang="css" scoped>
    .vue-slide-bar {
        width: 100%;
    }
    .vue-slide-bar {
        position: relative;
    }
    .vue-slide-bar {
        z-index: 9999;
    }
    .modal-body {
        background: #fff;
        border-radius: 0 0 4px 4px;
        display: flex;
        height: 200px !important;
        align-items: center;
    }
    
    .no-question {
        text-align: center;
        align-items: center;
        width: 100%;
        margin: auto 0;
        display: block;
    }
    
    .btn-secondary {
        color: white;
    }
    
    .accordion .card .card-header * {
        color: white;
    }
    .modal-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    @media (max-width: 500px) {
        .modal-btns .row button {
            font-size: 12px !important;
        }
    }
    @media (max-width: 425px) {
        .new-stretch-card {
            flex-direction: column;
        }
        .new-stretch-card .ml-1 {
            margin-left: 0px !important;
        }
        .modal-btns {
            justify-content: center !important;
        }
    }
</style>
