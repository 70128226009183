<template>
       <!-- <div id="question-counts"  > -->
    <b-sidebar id="sidebar-no-header" class="my-sidebar" aria-labelledby="sidebar-no-header-title" right shadow>
      <template #default="{ hide }">
        <div class="card">
          <div class="card-body p-3" style="padding: 20px !important;">
            <h4 id="sidebar-no-header-title">{{ $t('total-spots') }}: {{showTotalSpots}}</h4> 
            <div class="dashboard-box-counter"></div>
          </div>
        </div>
        <div class="p-3" style="padding: 20px !important;">
          <h4 id="sidebar-no-header-title">{{ $t('total-category-question-counts') }}:</h4>                       
        </div>
        <div v-if="countableQuestions.length > 0">
            <div v-for="question in getQuestions" :key="question.id" class="questions">
                <ul class="q-counts">
                    <li>
                        <label class="map-label" :for="question.id">{{ question.name }}</label> 
                    </li>
                    <li style="width: 20%;">
                        = {{ question.answerCount }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <ul class="text-center">
                <label class="map-label">{{ $t('no-countable-question-found') }}</label>
            </ul> 
        </div>
        <b-button variant="warning" block @click="hide">{{  $t('close-btn') }}</b-button>
      </template>
    </b-sidebar>
        <!-- </div> -->
      
</template>

<script>
 import Vue from "vue";
 import API from '@/api';
//  import API from '@/citizen_api';
 export default {
    props: ['totalSpots', 'client_url', 'user_type'],
    name:"sidebar-no-header",
        data() {
            return {
                countableQuestions: [],
                // totalSpots: '',
                params: {
                    spotId: '',
                    dateFrom: '',
                    dateTo: '',
                    questions: [],
                    categories: [],
                    locations: [],
                    spotTypes: [],
                    selected_questions: [],
                    selectedQuestions: [],
                    selected_questions_answer: [],
                    question_responses: [],
                }
            };
        },
       computed: {
            showTotalSpots() {
                return this.totalSpots
            },
            getQuestions() {
                return this.countableQuestions;
            }
        },
        beforeMount() {
            this.getQuestionAnswerCounts(this.params);
        },
        mounted() {},
        created() {},
        methods: {
            getQuestionAnswerCounts(params) {
                // const data = {
                //     client_id: JSON.parse(localStorage.getItem('client_id')),
                // }
                console.log('data :', data);
                let data = {
                    spot_id: params.spotId,
                    date_from: params.dateFrom,
                    date_to: params.dateTo,
                    questions: params.questions,
                    categories: params.categories,
                    locations: params.locations,
                    spotTypes: params.spotTypes,
                    selectedQuestions: params.selectedQuestions,
                    selected_questions: params.selected_questions,
                    selected_questions_answer: params.selected_questions_answer,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    client_url: this.client_url,
                    user_type: this.user_type,
                }
                API.getQuestionAnswerCounts(
                    data,
                    data => {
                    this.totalSpots = data.data1.total_spots;
                    this.countableQuestions = data.data;
                    },
                    err => {}
                );
            },
            assignQuestions(questions) {
                console.log('questions :', questions);
                this.countableQuestions = questions;
            }
            
        }
    };
</script>

<style scoped>
    #question-counts {
        position: relative;
        /* width: 500px; */
        height: 400px;
        background: #efefef;
        padding: 10px;
        padding-top: 8px;
        font-family: 'Open Sans', sans-serif;
        z-index: 999 !important;
        left: 600px;
        height: 58px;
    }
    ul.q-counts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;
    }
    ul.q-counts li {
        list-style-type: none;
    }
    ul.q-counts li label {
        margin-bottom: 0px;
    }
    ul.q-counts li:nth-child(1) {
        width: 80%;
    }
    /* .my-sidebar.b-sidebar-outer {
        position: absolute !important;
        width: 80% !important;
        height: 80% !important;
        top: 10%;
        left: 10%;
    } */

    /* .my-sidebar .b-sidebar {
        position: absolute !important;
        height: 100% !important;
    } */
    @media (max-width: 1150px) {
    .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>


